<template>
  <section class="bg-hero font-serif">
    <header class=" bg-white relative overflow-y-hidden pt-98 pb-105">
      <div
        class="text pl-16 pr-12 pt-5 md:pt-8 lg:pt-5 text-center md:text-center  "
      >
        <h1
          class="font-bold leading-loose md:leading-58 text-transpurple text-2xl md:text-5xl"
        >
          Explore Corruption
        </h1>
        <h1
          class="font-bold leading-loose md:leading-58 text-transpurple text-2xl md:text-5xl"
        >
          Cases In Nigeria
        </h1>
        <p class="text-17 leading-28  text-transgray500 pt-5">
          This is a central database to find details of ongoing and decided
          corruption
        </p>
        <p class="text-17 leading-28 text-transgray500">
          cases in the country with simplified infographics.
        </p>
      </div>
    </header>
  </section>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style scoped></style>
